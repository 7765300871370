@font-face {
  font-family: 'San Francisco';
  src: url("../fonts/aux-mono/aux-mono.otf");
  font-weight: 200;
}

@font-face {
  font-family: 'San Francisco Bold';
  src: url("../fonts/aux-mono/aux-mono.otf");
}

body {
  margin: 0;
  font-family: 'San Francisco';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-right: 0px !important;
}

html, body {
  margin: 0;
  overflow-x: hidden;
  max-width: 100vw;
  overflow-x: hidden;
}

h4 {
  margin: 0;
  margin-bottom: 10px;
}

#canvas {
  height: 100vh;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.MuiButton-outlined {
  border: 2px solid #666666;
}

.demo-option {
  width: 20vw;
  clear: both;
}

#landing-main {
  transition: color 2s ease;
}

.MuiInputLabel-shrink {
  top: 2px !important;
}

.web3modal-modal-card {
  min-width: 0px !important;
}

.MuiTable-stickyHeader {
  border-collapse: collapse !important;
}

.MuiButton-outlined, .MuiOutlinedInput-notchedOutline {
  border: 2px solid #666666;
}

.MuiFormLabel-root.Mui-focused {
    color: #999999 !important;
}

.MuiInput-underline:before, .MuiInput-underline:active:before {
  border: none !important;
}

.MuiInputAdornment-positionEnd {
    margin-left: 2px !important;
}

.MuiTableRow-root.Mui-selected {
  background-color: rgba(102, 255, 255, 0.5) !important;
}

#passed span {
  font-family: 'San Francisco Bold' !important;
  background: #00e79a !important;
  padding: 3.75px 7.5px !important;
  font-size: .875em;
  text-align: center;
}

#passed span span {
  width: 100px;
}

#active span {
  font-family: 'San Francisco Bold' !important;
  background: #00e79a !important;
  padding: 3.75px 7.5px !important;
  font-size: .875em;
  text-align: center;
}

#active span span {
  width: 100px;
}

#executed span {
  font-family: 'San Francisco Bold' !important;
  background: #66FFFF !important;
  padding: 3.75px 7.5px !important;
  font-size: .875em;
  text-align: center;
}

#expired span {
  font-family: 'San Francisco Bold' !important;
  background: #666666 !important;
  padding: 3.75px 7.5px !important;
  font-size: .875em;
  text-align: center;
}

#executed span span {
  width: 100px;
}

#expired span span {
  width: 100px;
}

#rejected, #passed, #executed, #active, #expired {
  margin-top: 7.5px;
}

#rejected o, #passed o, #executed o, #active o, #expired o {
  margin-left: 5px;
  padding-top: 10px;
  float: 'right';
}

::-webkit-scrollbar {
    width: .675em;
    z-index: 4 !important;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: rgba(	102, 102, 102, 0.375);
 }

#rejected o {
  color: #ff005a!important;
}

#passed o {
  color: #00e79a !important;
}

#executed o {
  color: #66FFFF !important;
}

#active o {
  color: #645eff !important;
}

#rejected span {
  font-family: 'San Francisco Bold' !important;
  background: #ff005a!important;
  padding: 3.75px 7.5px !important;
  font-size: .875em;
  text-align: center;
}

#rejected span span {
  width: 100px;
}

#inactive {
  color: #66FFFF !important;
}

#registered {
  color: #00e79a !important;
}

#delegated {
  color: orange !important;
}

code {
  background: #c8c8c8;
  font-family: San Francisco;
  color: #333333;
  border-radius: 2.5px;
  padding: 2.5px;
}

blockquote {
  padding: 0 5px;
  border-left: 3px solid #c8c8c8;
  margin: 0 17.5px;
}

blockquote p {
  color: #7a7a7a !important;
  margin-left: 7.5px;
}

pre code {
    display: flex;
    font-size: inherit;
    word-break: normal;
    padding: 10px 15px;
    border-radius: 5px;
    background: #c8c8c8;
    font-family: San Francisco;
    color: #333333;
    width: auto;
}

a {
  color: inherit !important;
}

a:hover {
  color: #00e79a !important;
}


.mouse_wave {
  position: absolute;
	display: block;
	margin: 0 auto;
	width: 30px;
	height: 100px;
}
.scroll_arrows
{
  display: block;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);

  border-right: 4px solid black;
  border-bottom: 4px solid black;
  margin: 0 0 3px 4px;

  width: 22.5px;
  height: 22.5px;
}
.one
{
  margin-top: 1px;
}
.one, .two, .three
{
    -webkit-animation: mouse-wave 1s infinite;
    -moz-animation: mouse-wave 1s infinite;
}
.one
{
  -webkit-animation-delay: .1s;
  -moz-animation-delay: .1s;
  -webkit-animation-direction: alternate;
}

.two
{
  -webkit-animation-delay: .2s;
  -moz-animation-delay: .2s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}

.three
{
  -webkit-animation-delay: .3s;
  -moz-animation-delay: .3s;
  -webkit-animation-direction: alternate;
  margin-top: -6px;
}


@-webkit-keyframes mouse-wave {

  0%   { opacity: 0;}
  50%  { opacity: .5;}
  100% { opacity: 1;}
}
@-moz-keyframes mouse-wave {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@-o-keyframes mouse-wave {

  0%   { opacity: 0; }
  50%  { opacity: .5; }
  100% { opacity: 1; }
}
@keyframes mouse-wave {

  0%   { opacity: 0; border-color: white; }
  50%  { opacity: .5; border-color: black; }
  100% { opacity: 1; border-color: white;}
}


@media only screen and (min-width: 1921px) and (max-width: 2749px) {

  .MuiGrid-container {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 67.5% !important;
  }

  .MuiGrid-container .MuiGrid-container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

}

@media only screen and (min-width: 2750px) {

  .MuiGrid-container {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 50% !important;
  }

  .MuiGrid-container .MuiGrid-container {
    width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

}

@media only screen and (max-width: 600px) {

  #landing-button {
    margin-left: 50px !important;
    margin-right: 25px !important;
  }

  h1 {
    font-size: 1.5em !important;
  }

}

@media only screen and (max-width: 321px) {

  h1 {
    font-size: 1.25em !important;
  }

}
